<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="data"
              :sort-mapping="sortMapping"
              :title="'reports'"
              :total="dataTotal"
              :fetch-path="'reports/filter'"
              :filter-path="'reports/filter'"
            >
              <template v-slot="{field}">
                <td class="text-end">
                  <span
                    class="dis-ib"
                  >
                    <a
                      class="btn btn-icon rounded-circle btn-flat-dark"
                      target="_blank"
                      :href="field.url.includes('grafana') ? field.url : $router.resolve({name: 'admin.reports.show', params: {report_id: field.id}}).href"
                    >
                      <i data-feather="eye" />
                    </a>
                  </span>
                </td>
              </template>
              <template #customfilters>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Name</label>
                  <input
                    v-model="filters.name"
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter({ customLabel: true, name: `Name: ${$event.target.value}`, value: $event.target.value }, 'name')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Report category</label>
                  <v-select
                    v-model="filters.report_category_id"
                    label="name"
                    :options="categories"
                    :get-option-key="option => option.name"
                    @input="saveFilter({ customLabel: true, name: `Report category: ${$event ? $event.name : ''}`, value: $event }, 'report_category_id')"
                  />
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import CompleteTable from '../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
  },
  data() {
    return {
      sortedField: 'Order',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Category',
          checked: true,
          order: 2,
        },
      ],
      sortMapping: {
        Name: 'name',
        Category: 'request_category.name',
      },
      title: 'Reports',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      data: 'reports/items',
      categories: 'reports/categories',
      dataTotal: 'reports/itemsTotal',
      filters: 'filters/filters',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'reports')
    this.$store.dispatch('reports/fetchCategories')
    await this.$store.dispatch('reports/filter', this.$store.getters['filters/filters'])
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'reports',
      })
    }

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
  },
}
</script>
